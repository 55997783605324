import React from 'react'

const NotFoundPage = () => (
  <React.Fragment>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that does not exist... the sadness.</p>
  </React.Fragment>
)

export default NotFoundPage
